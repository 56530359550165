import classNames from "classnames";
import Image from "next/legacy/image";
import React, { CSSProperties } from "react";

// This component wraps Next <Image /> to more easily size and position
// inside of the landing sections, specifically for sizing and positioning
// by percentages.

export const SectionImage = ({
  sourceWidth,
  sourceHeight,
  width,
  height,
  src,
  style,
  className,
}: {
  sourceWidth: number;
  sourceHeight: number;
  width: number | string;
  height: number | string;
  src: string;
  style?: CSSProperties;
  className?: string;
}) => {
  return (
    <div className={classNames("section-image", className)} style={style}>
      <div className="inner">
        <Image
          src={src}
          width={sourceWidth}
          height={sourceHeight}
          layout="responsive"
          priority
        />
      </div>

      <style jsx>{`
        .section-image {
          width: ${width};
          padding-top: ${height};

          .inner {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }
      `}</style>
    </div>
  );
};
