import { LuxLink } from "@lux/atoms/components/LuxLink";
import { ResponsiveBreakpoint } from "@lux/atoms/utils/StyleConstants";
import TwitterSvg from "@lux/icons/brands/Twitter.svg";
import React from "react";
import classNames from "classnames";

export const Footer = ({ className }: { className?: string }) => {
  return (
    <div className={classNames("footer", className)}>
      <div className="inner flex flex-wrap">
        <ul className="flex flex-wrap my-0 ml-0 p-0">
          <li>
            <LuxLink href="https://docs.glow.app">Docs</LuxLink>
          </li>
          <li>
            <LuxLink href="/support">Support</LuxLink>
          </li>
          <li>
            <LuxLink href="/terms">Terms</LuxLink>
          </li>
          <li>
            <LuxLink href="/privacy-policy">Privacy</LuxLink>
          </li>
          <li className="desktop-only">
            <LuxLink href="/bug-bounty">Bug Bounty</LuxLink>
          </li>
          <li className="twitter flex-center-center">
            <LuxLink href="https://twitter.com/glowwallet">
              <TwitterSvg />
            </LuxLink>
          </li>
        </ul>
      </div>

      <style jsx>{`
        .footer {
          position: absolute;
          bottom: 0;
          left: 0;

          @media (max-width: ${ResponsiveBreakpoint.medium}) {
            position: relative;
            margin-top: 0.75rem;
            padding-bottom: 2rem;

            .inner {
              align-items: center;
              justify-content: center;
            }
          }

          @media (max-width: ${ResponsiveBreakpoint.tiny}) {
            font-size: var(--font-size-sm);
          }

          ul {
            margin-right: 3rem;
            gap: 0.75rem;

            @media (max-width: ${ResponsiveBreakpoint.large}) {
              margin-right: 0;
            }
          }

          ul,
          li {
            list-style: none;
          }

          li {
            :global(a) {
              color: var(--tertiary-color);
            }

            :global(a:hover) {
              color: var(--secondary-color);
            }
          }
        }
      `}</style>
    </div>
  );
};
