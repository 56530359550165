import { ResponsiveBreakpoint } from "@lux/atoms/utils/StyleConstants";
import classNames from "classnames";
import React from "react";

export const SectionTitle = ({
  title,
  icon,
  color,
  className,
}: {
  title: string | React.ReactNode;
  icon: React.ReactNode;
  color: string;
  className?: string;
}) => {
  return (
    <div className={classNames("section-title", className)}>
      <div className="section-icon flex-center-center">
        <div className="icon flex-center-center">{icon}</div>
      </div>
      <h2 className="m-0 text-center">{title}</h2>

      <style jsx>{`
        .section-title {
          margin: 2.5rem 0;

          @media (max-width: ${ResponsiveBreakpoint.small}) {
            margin: 2.25rem 0 1.75rem 0;
          }

          @media (max-width: ${ResponsiveBreakpoint.tiny}) {
            margin: 2rem 0 1.75rem 0;
          }

          .section-icon {
            margin: 0 0 1rem 0;
            color: white;

            @media (max-width: ${ResponsiveBreakpoint.small}) {
              margin: 0 0 0.75rem 0;
            }

            .icon {
              width: 48px;
              height: 48px;
              background-color: ${color};
              border-radius: 100px;

              :global(svg) {
                width: 50%;
                height: 50%;
              }

              @media (max-width: ${ResponsiveBreakpoint.large}) {
                width: 42px;
                height: 42px;
              }

              @media (max-width: ${ResponsiveBreakpoint.medium}) {
                width: 48px;
                height: 48px;
              }

              @media (max-width: ${ResponsiveBreakpoint.small}) {
                width: 42px;
                height: 42px;
              }

              @media (max-width: ${ResponsiveBreakpoint.tiny}) {
                width: 36px;
                height: 36px;
              }
            }
          }

          h2 {
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 128.5%;

            @media (max-width: ${ResponsiveBreakpoint.small}) {
              font-size: 1.375rem;
            }

            @media (max-width: ${ResponsiveBreakpoint.tiny}) {
              font-size: 1.25rem;
            }
          }
        }
      `}</style>
    </div>
  );
};
