import React from "react";

export const NOTO_FONTS =
  "Noto Sans, Noto Sans JP, Noto Sans KR, Noto Sans SC, Noto Sans TC";

export const LoadRoobert = () => {
  return (
    <>
      <style jsx global>{`
        .font-roobert {
          font-family: "Roobert", ${NOTO_FONTS};
        }

        @font-face {
          font-family: "Roobert";
          src: url("/roobert/Roobert-SemiBold.otf") format("opentype");
          font-style: normal;
          font-weight: 600;
        }

        @font-face {
          font-family: "Roobert";
          src: url("/roobert/Roobert-Medium.otf") format("opentype");
          font-style: normal;
          font-weight: 500;
        }

        @font-face {
          font-family: "Roobert";
          src: url("/roobert/Roobert-Regular.otf") format("opentype");
          font-style: normal;
          font-weight: 400;
        }
      `}</style>
    </>
  );
};
