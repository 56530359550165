import ArrowDownSvg from "@lux/icons/feather/arrow-down.svg";
import { ResponsiveBreakpoint } from "@lux/atoms/utils/StyleConstants";
import { useIsSafari16 } from "@lux/atoms/hooks/useIsSafari16";
import classNames from "classnames";
import React from "react";

import { SectionImage } from "./SectionImage";

export const Intro = () => {
  // Safari 16 doesn't work with filter that has multiple drop shadows.
  // This is most likely a bug. When this is fixed, we should remove this.
  const isSafari16 = useIsSafari16();

  return (
    <div className="intro relative">
      <SectionImage
        src="/landing/sections/intro/bg.png"
        sourceWidth={2070}
        sourceHeight={825}
        width="100%"
        height="35%"
        className="bg light-only"
      />
      <SectionImage
        src="/landing/sections/intro/bg-dark.png"
        sourceWidth={2070}
        sourceHeight={825}
        width="100%"
        height="35%"
        className="bg dark-only"
      />
      <div className="flex-center-center inner">
        <SectionImage
          src="/landing/sections/intro/device.png"
          sourceWidth={797}
          sourceHeight={1612}
          width="38%"
          height="77%"
          className={classNames("relative device light-only", {
            "safari-16": isSafari16,
          })}
        />
        <SectionImage
          src="/landing/sections/intro/device-dark.png"
          sourceWidth={797}
          sourceHeight={1612}
          width="38%"
          height="77%"
          className={classNames("relative device dark-only", {
            "safari-16": isSafari16,
          })}
        />
      </div>
      <div className="cta text-center text-secondary mb-4">
        <span className="block">Explore</span>{" "}
        <span className="block">Glow Features</span>
      </div>
      <div className="arrow flex-center-center relative">
        <div className="inner relative" />
        <ArrowDownSvg />
      </div>

      <style jsx>{`
        .intro {
          padding-top: 6.25rem;

          @media (max-width: ${ResponsiveBreakpoint.medium}) {
            padding-top: 3rem;
          }

          :global(.bg) {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          > .inner {
            margin-bottom: 3rem;
          }

          :global(.device) {
            transform: translateZ(0px);
            perspective: 1000;
            backface-visibility: hidden;

            filter: drop-shadow(78px 136px 94px rgba(0, 0, 0, 0.02))
              drop-shadow(35px 60px 69px rgba(0, 0, 0, 0.04))
              drop-shadow(9px 15px 38px rgba(0, 0, 0, 0.05))
              drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05));

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              filter: drop-shadow(44px 77px 53px rgba(0, 0, 0, 0.02))
                drop-shadow(19px 34px 39px rgba(0, 0, 0, 0.04))
                drop-shadow(4px 8px 21px rgba(0, 0, 0, 0.05))
                drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05));
            }
          }

          :global(.device.safari-16) {
            filter: drop-shadow(35px 60px 69px rgba(0, 0, 0, 0.04));

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              filter: drop-shadow(19px 34px 39px rgba(0, 0, 0, 0.04));
            }
          }

          .cta {
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 1.5;
            letter-spacing: 1px;
            text-transform: uppercase;

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              display: none;
            }
          }

          .arrow {
            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              display: none;
            }

            :global(svg) {
              width: 20px;
              height: 20px;
              color: var(--secondary-color);
              position: absolute;
              left: 50%;
              bottom: 7px;
              transform: translateX(-50%);
            }

            .inner {
              width: 82px;
              height: 41px;
              overflow: hidden;

              ::before {
                content: "";
                display: block;
                width: 100%;
                padding-top: 100%;
                border-radius: 50%;
                background: var(--primary-bg-color);
                position: absolute;
                left: 0;
                top: 0;
              }
            }
          }
        }
      `}</style>
    </div>
  );
};
