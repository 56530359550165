import { SolanaPage } from "@components/nav/SolanaPage";
import { Solana } from "@luma-team/shared";
import { LuxInputField } from "@lux/atoms/components/LuxInput";
import { LuxTextareaField } from "@lux/atoms/components/LuxTextarea";
import { ZmClient } from "@utils/ZmClient";
import { Form, Formik } from "formik";
import React from "react";
import { LuxSubmitButton } from "@lux/atoms/components/LuxSubmitButton";

/**
 * We need to deploy something to glow.new to keep the domain, so that's
 * what this is...
 */
export const GlowDotNewPage = () => {
  return (
    <SolanaPage title="Make Transfer">
      <div className="zm-container p-5 mx-auto" style={{ maxWidth: 350 }}>
        <h2>Make Transfer</h2>

        <div>
          <Formik
            initialValues={{ lamports: "0", recipient: "", note: "" }}
            onSubmit={async ({ note, recipient, lamports }) => {
              const { address } = await window.glow!.connect();
              const { transaction_base64 } = await ZmClient.post(
                "/solana/transaction/construct-sol-transfer",
                {
                  network: Solana.Network.Mainnet,
                  from: address,
                  to: recipient,
                  lamports,
                  note: note,
                }
              );

              await window.glow!.signAndSendTransaction({
                transactionBase64: transaction_base64,
                network: Solana.Network.Mainnet,
              });
            }}
          >
            <Form className={"flex-column gap-3"}>
              <LuxInputField
                name={"recipient"}
                label={"Recipient"}
                monospace
                placeholder={"JK5..."}
              />

              <LuxInputField
                name={"lamports"}
                label="Lamports to Send"
                type={"numeric"}
              />

              <LuxTextareaField
                name={"note"}
                label={"Note"}
                placeholder={"Enter a note..."}
                textAreaProps={{ minRows: 2 }}
              />

              <LuxSubmitButton label={"Confirm"} />
            </Form>
          </Formik>
        </div>
      </div>
    </SolanaPage>
  );
};
