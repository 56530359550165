import { LuxModalContainer } from "@lux/atoms/components/LuxModal";
import { ResponsiveBreakpoint } from "@lux/atoms/utils/StyleConstants";
import AppleIcon from "@lux/icons/brands/Apple.svg";
import AndroidIcon from "@lux/icons/brands/Android.svg";
import React, { useState } from "react";

import { GlowButton } from "@components/atoms/GlowButton";
import { DESKTOP_DOWNLOADS } from "@utils/browser";
import { DownloadAppModal } from "@components/nav/DownloadAppModal";
import { mobileDownloadUrl } from "@utils/download";
import { DownloadDesktopType } from "../../../pages/download";

export const Download = () => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const downloadClick = () => {
    const url = mobileDownloadUrl({ ua: navigator.userAgent, fallback: "" });
    if (url !== "") {
      window.open(url, "_blank");
    } else {
      setShowDownloadModal(true);
    }
  };

  return (
    <div className="download" id="download-section">
      <div className="flex-center flex-column">
        <h2>
          <span className="block get-glow">Get Glow Now</span>
        </h2>
        <div className="mt-4 text-center">
          <h3 className="mb-3">Desktop</h3>
          <p className="mb-3">
            <span className="block">Turn your browser into an</span>{" "}
            <span className="block">easy-to-use Solana wallet.</span>
          </p>
        </div>
        <div className="flex-center">
          {DESKTOP_DOWNLOADS.map((download) => (
            <DownloadDesktopType
              key={download.icon}
              href={download.href}
              iconSrc={`/download/${download.icon}`}
              label={download.label}
            />
          ))}
        </div>
        <div className="mt-4 text-center">
          <h3 className="mb-3">Mobile</h3>
          <p className="mb-3">
            <span className="block">Use Glow and your favorite</span>{" "}
            <span className="block">web3 apps on the go.</span>
          </p>
        </div>
        <div className="flex-center gap-3 mt-2">
          <GlowButton
            onClick={downloadClick}
            label="Get for iOS"
            icon={<AppleIcon />}
            color="primary"
            rounded
          />
          <GlowButton
            onClick={downloadClick}
            label="Get for Android"
            icon={<AndroidIcon />}
            color="primary"
            rounded
          />
        </div>
      </div>

      <LuxModalContainer
        variant="lux-alert"
        onHide={() => setShowDownloadModal(false)}
      >
        {showDownloadModal && <DownloadAppModal />}
      </LuxModalContainer>

      <style jsx>{`
        .download {
          padding: 4rem 0;

          @media (max-width: ${ResponsiveBreakpoint.large}) {
            padding: 3rem 0;
          }

          @media (max-width: ${ResponsiveBreakpoint.medium}) {
            padding: 2.5rem 0;
          }

          @media (max-width: ${ResponsiveBreakpoint.tiny}) {
            padding: 2rem 0;
          }

          h2 {
            font-weight: 700;
            font-size: 2.5rem;
            line-height: 1.2;

            @media (max-width: ${ResponsiveBreakpoint.large}) {
              font-size: 2rem;
              margin-bottom: 0;
            }

            @media (max-width: ${ResponsiveBreakpoint.tiny}) {
              font-size: 1.75rem;
            }

            .get-glow {
              @supports (background-clip: text) or
                (-webkit-background-clip: text) {
                background: radial-gradient(
                  86% 86% at 6% 9%,
                  #8800ff 0%,
                  #a732d6 51%,
                  #ef79ff 100%
                );
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
                line-height: 110%;
                display: block;
                transform: translateZ(-1px);
              }
            }
          }

          h3 {
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 1.75rem;

            @media (max-width: ${ResponsiveBreakpoint.small}) {
              font-size: 1.25rem;
            }

            @media (max-width: ${ResponsiveBreakpoint.tiny}) {
              font-size: 1.125rem;
              margin-bottom: 0.5rem !important;
            }
          }
        }
      `}</style>
    </div>
  );
};
