import { ResponsiveBreakpoint } from "@lux/atoms/utils/StyleConstants";
import React from "react";

export const Section = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="section">
      {children}

      <style jsx>{`
        .section {
          width: 50%;
          margin-left: 50%;
          background: var(--secondary-bg-color);
          border-radius: 2.5rem;
          max-width: 700px;
          overflow: hidden;
          pointer-events: auto;
          margin-bottom: 1.5rem;

          @media (max-width: ${ResponsiveBreakpoint.medium}) {
            width: 100%;
            margin-left: 0;
            max-width: none;
            border-radius: 2rem;
          }
        }
      `}</style>
    </div>
  );
};
