import { ResponsiveBreakpoint } from "@lux/atoms/utils/StyleConstants";
import SwapIcon from "@lux/icons/glow/SwapFilled.svg";
import React from "react";

import { SectionImage } from "./SectionImage";
import { SectionTitle } from "./SectionTitle";

export const Swap = () => {
  return (
    <div className="swap relative">
      <SectionTitle
        title="Swap Coins with Zero Fees"
        icon={<SwapIcon />}
        color="var(--blue-50)"
      />
      <div className="flex-center-center">
        <SectionImage
          src="/landing/sections/swap/device.png"
          sourceWidth={1118}
          sourceHeight={1425}
          width="59%"
          height="75%"
          className="relative device light-only"
        />
        <SectionImage
          src="/landing/sections/swap/device-dark.png"
          sourceWidth={1118}
          sourceHeight={1425}
          width="59%"
          height="75%"
          className="relative device dark-only"
        />
      </div>
      <SectionImage
        src="/landing/sections/swap/coin-left.png"
        sourceWidth={574}
        sourceHeight={574}
        width="28%"
        height="30%"
        className="coin-left light-only"
      />
      <SectionImage
        src="/landing/sections/swap/coin-left-dark.png"
        sourceWidth={574}
        sourceHeight={574}
        width="28%"
        height="30%"
        className="coin-left dark-only"
      />
      <SectionImage
        src="/landing/sections/swap/coin-right.png"
        sourceWidth={410}
        sourceHeight={410}
        width="20%"
        height="21%"
        className="coin-right light-only"
      />
      <SectionImage
        src="/landing/sections/swap/coin-right-dark.png"
        sourceWidth={410}
        sourceHeight={410}
        width="20%"
        height="21%"
        className="coin-right dark-only"
      />

      <style jsx>{`
        .swap {
          :global(.device) {
            transform: translateZ(0px);
            perspective: 1000;
            backface-visibility: hidden;

            filter: drop-shadow(
                44.0512px 75.713px 86.7258px rgba(0, 0, 0, 0.04)
              )
              drop-shadow(11.0128px 19.2724px 48.181px rgba(0, 0, 0, 0.05))
              drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05));

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              filter: drop-shadow(
                  25.9266px 44.5613px 51.043px rgba(0, 0, 0, 0.04)
                )
                drop-shadow(6.48165px 11.3429px 28.3572px rgba(0, 0, 0, 0.05))
                drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05));
            }
          }

          :global(.coin-left) {
            position: absolute;
            left: 3%;
            bottom: 32%;
          }

          :global(.coin-right) {
            position: absolute;
            right: 7%;
            bottom: 26%;
          }
        }
      `}</style>
    </div>
  );
};
