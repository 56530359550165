import { SocialHead } from "@components/all-pages/SocialHead";
import { LoadRoobert } from "@lux/atoms/components/LoadRoobert";
import { Footer } from "@components/landing/main/Footer";
import { Logo } from "@components/landing/main/Logo";
import { Main } from "@components/landing/main/Main";
import { Activity } from "@components/landing/sections/Activity";
import { Burn } from "@components/landing/sections/Burn";
import { DApps } from "@components/landing/sections/DApps";
import { Download } from "@components/landing/sections/Download";
import { Explore } from "@components/landing/sections/Explore";
import { Intro } from "@components/landing/sections/Intro";
import { Section } from "@components/landing/sections/Section";
import { Stake } from "@components/landing/sections/Stake";
import { Swap } from "@components/landing/sections/Swap";
import { GlowDotNewPage } from "@components/vanity/GlowDotNewPage";
import { PhantomDotNewPage } from "@components/vanity/PhantomDotNewPage";
import { WalletTheme } from "@glow/react/components/wallet-theme";
import { ResponsiveBreakpoint } from "@lux/atoms/utils/StyleConstants";
import { GetServerSideProps } from "next";
import React from "react";

const SECTIONS = [
  { component: Intro, theme: WalletTheme.Barney },
  { component: Swap, theme: WalletTheme.Blue },
  { component: Activity, theme: WalletTheme.Yellow },
  { component: Explore, theme: WalletTheme.Green },
  { component: Burn, theme: WalletTheme.Cranberry },
  { component: Stake, theme: WalletTheme.Purple },
  { component: DApps, theme: WalletTheme.Yellow },
  { component: Download, theme: WalletTheme.Barney },
];

const Content = () => {
  return (
    <div className="glow font-roobert">
      <LoadRoobert />
      <div className="main">
        <div className="inner">
          <div className="content">
            <Logo />
            <Main />
            <Footer className="desktop-footer" />
          </div>
        </div>
      </div>
      <div className="sections">
        <div className="relative inner">
          {SECTIONS.map(({ component: SectionType }, sx) => (
            <Section key={sx}>
              <SectionType />
            </Section>
          ))}
        </div>
      </div>

      <Footer className="mobile-footer" />

      <style jsx>{`
        .glow {
          .main {
            position: fixed;
            left: 1.5rem;
            top: 1.5rem;
            width: calc(100% - 3rem);
            height: calc(100% - 3rem);

            .inner {
              height: 100%;
            }

            .content {
              width: 50%;
              height: 100%;
              position: relative;
              min-height: 650px;

              @media (max-width: ${ResponsiveBreakpoint.medium}) {
                min-height: 0;
                height: auto;
              }
            }

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              position: relative;
              left: 1rem;
              top: 1rem;
              width: calc(100% - 2rem);
              height: calc(100% - 2rem);

              .content {
                width: 100%;
              }
            }
          }

          .sections {
            pointer-events: none;
            padding-top: 1.5rem;
            margin-left: 2rem;
            width: calc(100% - 4rem);

            .inner {
              padding-bottom: 1rem;
            }

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              padding-top: 1rem;
              margin-left: 1rem;
              width: calc(100% - 2rem);

              .inner {
                padding-bottom: 0;
              }
            }
          }

          .inner {
            position: relative;
          }

          :global(.desktop-footer) {
            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              display: none;
            }
          }

          :global(.mobile-footer) {
            display: none;

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              display: block;
            }
          }
        }
      `}</style>
    </div>
  );
};

const Home = ({
  isGlowDotNew,
  isPhantomDotNew,
}: {
  isGlowDotNew: boolean;
  isPhantomDotNew: boolean;
}) => {
  if (isGlowDotNew) {
    return <GlowDotNewPage />;
  }
  if (isPhantomDotNew) {
    return <PhantomDotNewPage />;
  }

  return (
    <div className="home">
      <SocialHead
        title="Glow — Your New Favorite Solana Wallet"
        description="With Glow, enjoy zero fee swaps, in-app staking, NFT explorer, spam token burner and more in an elegant wallet."
        canonicalPath="/"
        imageSize={{ width: 1200, height: 628 }}
        imageUrl="https://cdn.glow.app/social/homepage.png"
      />
      <Content />
    </div>
  );
};

export default Home;

export const getServerSideProps: GetServerSideProps = async () => {
  return {
    props: {
      isGlowDotNew: process.env.IS_GLOW_DOT_NEW === "1",
      isPhantomDotNew: process.env.IS_PHANTOM_DOT_NEW === "1",
    },
  };
};
