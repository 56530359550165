import { isAndroid, isIos } from "@luma-team/shared";
import { ExternalLink } from "@lux/atoms/components/ExternalLink";
import { LuxModalContainer } from "@lux/atoms/components/LuxModal";
import { useIsMounted } from "@lux/atoms/hooks/useIsMounted";
import { WalletTheme, WalletThemes } from "@glow/react/components/wallet-theme";
import { ResponsiveBreakpoint } from "@lux/atoms/utils/StyleConstants";
import classNames from "classnames";
import React, { useState } from "react";

import { GlowButton } from "@components/atoms/GlowButton";
import { DownloadAppModal } from "@components/nav/DownloadAppModal";
import { BROWSER_INFO, useCurrentBrowser } from "@utils/browser";
import {
  ANDROID_DOWNLOAD_LINK,
  IOS_DOWNLOAD_LINK,
  mobileDownloadUrl,
} from "@utils/download";

const BrowserDownloadButton = () => {
  const browser = useCurrentBrowser();

  if (browser) {
    const info = BROWSER_INFO[browser];
    return (
      <GlowButton
        label={`Get for ${info.label}`}
        className="mr-2 mt-2 desktop-only"
        color="brand"
        rounded
        href={info.href}
      />
    );
  }

  return (
    <GlowButton
      label="Get Browser Extension"
      className="mr-2 mt-2 desktop-only"
      color="brand"
      rounded
      onClick={() => {
        const el = document.getElementById("download-section");
        el?.scrollIntoView({ behavior: "smooth" });
      }}
    />
  );
};

const MobileDownloadButton = ({ showModal }: { showModal: () => void }) => {
  const isMounted = useIsMounted();
  const downloadClick = () => {
    const url = mobileDownloadUrl({ ua: navigator.userAgent, fallback: "" });
    if (url !== "") {
      window.open(url, "_blank");
    } else {
      showModal();
    }
  };

  let os = null;
  if (isMounted) {
    if (isIos(navigator.userAgent)) {
      os = "ios";
    } else if (isAndroid(navigator.userAgent)) {
      os = "android";
    }
  }

  if (os === "ios") {
    return (
      <GlowButton
        label="Download for iOS"
        className="mr-3 mt-2"
        color="brand"
        rounded
        onClick={() => {
          window.open(IOS_DOWNLOAD_LINK, "_blank");
        }}
      />
    );
  }

  if (os === "android") {
    return (
      <GlowButton
        label="Download for Android"
        className="mr-3 mt-2"
        color="brand"
        rounded
        onClick={() => {
          window.open(ANDROID_DOWNLOAD_LINK, "_blank");
        }}
      />
    );
  }

  return (
    <GlowButton
      className="mr-3 mt-2"
      label="Get Mobile App"
      color="brand"
      rounded
      onClick={downloadClick}
    />
  );
};

export const Main = () => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const theme = WalletTheme.Barney;

  return (
    <div className={"main flex-center-center theme-root"}>
      <div className="content">
        <div className="app-icon">
          <div />
          {WalletThemes.map((iconTheme, ix) => (
            <img
              key={ix}
              src={`/landing/app-icons/${iconTheme}.png`}
              className={classNames({
                active: theme === iconTheme,
              })}
            />
          ))}
        </div>

        <div
          className="banner flex-center"
          onClick={() => {
            window.open(ANDROID_DOWNLOAD_LINK, "_blank");
          }}
        >
          <div className="badge text-xs b">NEW</div>
          <div>Glow is now available on Android!</div>
        </div>

        <h2>Your new favorite Solana wallet awaits.</h2>

        <div className="flex flex-wrap buttons">
          <BrowserDownloadButton />
          <MobileDownloadButton
            showModal={() => {
              setShowDownloadModal(true);
            }}
          />
        </div>
        <div className="text-secondary mt-3 fs-sm mobile-only">
          Also available as a desktop browser extension.
        </div>

        <ExternalLink href="https://twitter.com/rajgokal/status/1489706531742371844">
          <div className="quote pt-3">
            <p className="text-secondary animated mb-2">
              “Great designers are finally coming to crypto.”
            </p>
            <div className="text-right flex-center text-tertiary">
              <span>— Raj Gokal</span>
              <span className="mx-2">•</span>
              <span>Solana Co-founder</span>
            </div>
          </div>
        </ExternalLink>
      </div>

      <LuxModalContainer
        variant="lux-alert"
        onHide={() => setShowDownloadModal(false)}
      >
        {showDownloadModal && <DownloadAppModal />}
      </LuxModalContainer>

      <style jsx>{`
        .main {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          @media (max-width: ${ResponsiveBreakpoint.medium}) {
            position: relative;
            height: auto;
            margin: 4.5rem 0;
            padding: 0 1.5rem;
          }

          @media (max-width: ${ResponsiveBreakpoint.small}) {
            padding: 0 1rem;
          }

          @media (max-width: ${ResponsiveBreakpoint.tiny}) {
            padding: 0 0.5rem;
            margin: 4rem 0;
          }

          .content {
            width: 450px;

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              width: auto;
              flex: 1;
            }
          }

          .app-icon {
            position: relative;
            width: 74px;
            height: 74px;

            img,
            div {
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              transition: var(--transition);
              border-radius: 18px;

              &.active {
                opacity: 1;
              }
            }

            div {
              transform: translateZ(0px);
              perspective: 1000;
              backface-visibility: hidden;
              background: var(--primary-bg-color);
              opacity: 1;
              filter: drop-shadow(0px 13px 13px rgba(0, 0, 0, 0.03))
                drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.04))
                drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.04));
            }

            @media (max-width: ${ResponsiveBreakpoint.large}) {
              width: 70px;
              height: 70px;
            }

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              width: 68px;
              height: 68px;
            }

            @media (max-width: ${ResponsiveBreakpoint.small}) {
              width: 64px;
              height: 64px;

              img,
              div {
                border-radius: 14px;
              }
            }

            @media (max-width: ${ResponsiveBreakpoint.tiny}) {
              width: 60px;
              height: 60px;
            }
          }

          .banner {
            margin: 2.5rem 1.5rem -1rem 0;
            background-color: var(--faint-orange);
            padding: 0.5rem 0.75rem;
            border-radius: var(--border-radius);
            color: var(--orange);
            cursor: pointer;

            .badge {
              color: white;
              background-color: var(--orange);
              padding: 0.125rem 0.5rem;
              border-radius: var(--small-border-radius);
              margin-right: 0.5rem;
            }

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              max-width: 425px;
            }

            @media (max-width: ${ResponsiveBreakpoint.small}) {
              margin-right: 0;
              margin-bottom: 0;
            }
          }

          h2 {
            font-weight: 600;
            font-size: 3.5rem;
            line-height: 1;
            letter-spacing: -1px;
            padding: 3rem 2rem 0.5rem 0;

            @media (max-width: ${ResponsiveBreakpoint.large}) {
              font-size: 3rem;
              max-width: 22rem;
              padding-top: 2.5rem;
            }

            @media (max-width: ${ResponsiveBreakpoint.medium}) {
              max-width: 30rem;
            }

            @media (max-width: ${ResponsiveBreakpoint.small}) {
              font-size: 2.75rem;
              max-width: 28rem;
            }

            @media (max-width: ${ResponsiveBreakpoint.tiny}) {
              font-size: 2.5rem;
              padding: 0;
              padding-top: 2rem;
            }
          }

          .buttons {
            margin-bottom: 3rem;

            @media (max-width: ${ResponsiveBreakpoint.small}) {
              margin-bottom: 0;
            }
          }

          .mobile-only {
            @media (max-width: ${ResponsiveBreakpoint.small}) {
              margin-bottom: 2rem;
            }
          }

          .quote {
            font-size: 1.125rem;
            line-height: 1.2;

            @media (max-width: ${ResponsiveBreakpoint.small}) {
              .text-tertiary {
                font-size: 1rem;
              }
            }

            &:hover {
              .text-secondary {
                color: var(--primary-color);
              }
            }
          }
        }
      `}</style>
    </div>
  );
};
