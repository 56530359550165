import { useState } from "react";
import { useOnMount } from "./useOnMount";

// Using useOnMount we don't cause SSR mismatches and errors.
export const useIsSafari16 = () => {
  const [isSafari16, setIsSafari16] = useState(false);
  useOnMount(() => {
    const isSafari16 =
      typeof navigator !== "undefined" &&
      navigator.vendor === "Apple Computer, Inc." &&
      navigator.userAgent?.indexOf?.("Version/16.") !== -1;

    if (isSafari16) {
      setIsSafari16(true);
    }
  });

  return isSafari16;
};
