import JigsawIcon from "@lux/icons/glow/JigsawFilled.svg";
import React from "react";
import { SectionImage } from "./SectionImage";
import { SectionTitle } from "./SectionTitle";

export const DApps = () => {
  return (
    <div className="d-apps">
      <SectionTitle
        title={
          <span>
            Connect to apps on desktop,
            <br />
            and on-the-go.
          </span>
        }
        icon={<JigsawIcon />}
        color="var(--yellow-60)"
      />
      <div className="relative full-width images">
        <SectionImage
          src="/landing/sections/dapps/browser.jpg"
          sourceWidth={1890}
          sourceHeight={1581}
          width="89%"
          height="74%"
          className="relative browser light-only"
        />
        <SectionImage
          src="/landing/sections/dapps/browser-dark.jpg"
          sourceWidth={1890}
          sourceHeight={1581}
          width="89%"
          height="74%"
          className="relative browser dark-only"
        />
        <SectionImage
          src="/landing/sections/dapps/device.png"
          sourceWidth={685}
          sourceHeight={1385}
          width="33%"
          height="60%"
          className="device light-only"
        />
        <SectionImage
          src="/landing/sections/dapps/device-dark.png"
          sourceWidth={685}
          sourceHeight={1385}
          width="33%"
          height="60%"
          className="device dark-only"
        />
      </div>

      <style jsx>{`
        .d-apps {
          .images {
            padding-top: 1.875rem;
          }

          :global(.device) {
            position: absolute;
            left: 16.23%;
            top: 0;
          }
        }
      `}</style>
    </div>
  );
};
